var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.areBuypassSettingsVisible)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"text-h3 mt-8 mb-5"},[_vm._v(" "+_vm._s(_vm.$t("generalSettingsStrings.buypassSettings.title"))+" ")]),_c('v-switch',{attrs:{"color":"primary","inset":"","label":_vm.$t('generalSettingsStrings.buypassSettings.labels.isActive'),"disabled":!_vm.isBuypassAdminForCurrentOrg},model:{value:(_vm.orgConfiguration.buypassSettings.isActive),callback:function ($$v) {_vm.$set(_vm.orgConfiguration.buypassSettings, "isActive", $$v)},expression:"orgConfiguration.buypassSettings.isActive"}}),(_vm.isBuypassAdminForCurrentOrg)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('validation-provider',{attrs:{"rules":{
            required_if: _vm.orgConfiguration.buypassSettings.isActive,
          },"name":_vm.$t(
              'generalSettingsStrings.buypassSettings.labels.settlementAccountId'
            )},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.availableBuypassAccounts,"item-value":"settlementAccountId","item-text":"name","label":_vm.$t(
                'generalSettingsStrings.buypassSettings.labels.settlementAccountId'
              ),"placeholder":_vm.$t(
                'generalSettingsStrings.buypassSettings.placeholders.settlementAccountId'
              ),"error-messages":errors,"outlined":"","dense":""},model:{value:(
              _vm.orgConfiguration.buypassSettings.settlementAccountId
            ),callback:function ($$v) {_vm.$set(_vm.orgConfiguration.buypassSettings, "settlementAccountId", $$v)},expression:"\n              orgConfiguration.buypassSettings.settlementAccountId\n            "}})]}}],null,false,2531816507)})],1)],1):_c('em',[_vm._v(_vm._s(_vm.$t('generalSettingsStrings.buypassSettings.editNotAvailableMessage')))]),_c('div',{staticClass:"mt-2"},[_c('buypass-settings-info-modal')],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }