var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"primary-inverted-button",attrs:{"rounded":"","small":""},on:{"click":_vm.onAdd}},[_c('v-icon',{attrs:{"tag":"span","left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("commonStrings.add"))+" ")],1),_c('v-dialog',{key:_vm.dimension.id,attrs:{"max-width":"500","persistent":""},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c('v-card',{attrs:{"loading":"isSaving"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.title))]),_c('v-card-text',[_c('validation-observer',{ref:"financialDimensionObserver"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t(
                      'financialDimensionStrings.dimensionsTable.headers.category'
                    )},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"dense":"","outlined":"","items":_vm.dimensionCategories,"label":_vm.$t(
                        'financialDimensionStrings.dimensionsTable.headers.category'
                      ),"error-messages":errors},model:{value:(_vm.dimension.categoryType),callback:function ($$v) {_vm.$set(_vm.dimension, "categoryType", $$v)},expression:"dimension.categoryType"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{
                    max: 5,
                    required: true,
                    is_not: _vm.usedCodesInCategory
                  },"name":_vm.$t(
                      'financialDimensionStrings.dimensionsTable.headers.code'
                    )},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('integer-text-field',{attrs:{"error-messages":errors,"label":_vm.$t(
                        'financialDimensionStrings.dimensionsTable.headers.code'
                      )},model:{value:(_vm.dimension.code),callback:function ($$v) {_vm.$set(_vm.dimension, "code", $$v)},expression:"dimension.code"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"max:50|required","name":_vm.$t(
                      'financialDimensionStrings.dimensionsTable.headers.name'
                    )},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t(
                        'financialDimensionStrings.dimensionsTable.headers.name'
                      ),"counter":"50","outlined":"","dense":""},model:{value:(_vm.dimension.name),callback:function ($$v) {_vm.$set(_vm.dimension, "name", $$v)},expression:"dimension.name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$t(
                      'financialDimensionStrings.dimensionsTable.headers.active'
                    )},model:{value:(_vm.dimension.active),callback:function ($$v) {_vm.$set(_vm.dimension, "active", $$v)},expression:"dimension.active"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary-inverted-button mb-2 mb-sm-0",attrs:{"rounded":"","block":_vm.$vuetify.breakpoint.xsOnly,"small":"","disabled":_vm.isSaving,"loading":_vm.isSaving},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("commonStrings.cancel"))+" ")]),_c('v-btn',{staticClass:"primary-button ml-0 ml-sm-2",attrs:{"rounded":"","block":_vm.$vuetify.breakpoint.xsOnly,"small":"","disabled":_vm.isSaving,"loading":_vm.isSaving},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("commonStrings.save"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }