var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.canHaveIntegrations)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-h2 mt-10 mb-7"},[_vm._v(" "+_vm._s(_vm.$t("generalSettingsStrings.integrationSettingsTableTitle"))+" ")]),_c('v-data-table',{attrs:{"dense":"","hide-default-footer":"","items":_vm.orgConfiguration.integrationSettings,"items-per-page":-1,"headers":_vm.integrationSettingsTableHeaders},scopedSlots:_vm._u([{key:"item.integrationConfigurationId",fn:function({ item }){return [_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t(
              'generalSettingsStrings.tableHeaders.integrationConfiguration'
            )},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"dense":"","outlined":"","items":_vm.availableIntegrationConfigurations,"label":_vm.$t(
                'generalSettingsStrings.placeholders.integrationConfiguration'
              )},model:{value:(item.integrationConfigurationId),callback:function ($$v) {_vm.$set(item, "integrationConfigurationId", $$v)},expression:"item.integrationConfigurationId"}})]}}],null,true)})]}},{key:"item.canUseSalaryFraction",fn:function({ item }){return [_c('v-checkbox',{attrs:{"color":"primary","label":_vm.$t('generalSettingsStrings.tableHeaders.salaryFraction'),"disabled":!item.integrationConfigurationId},model:{value:(item.canUseSalaryFraction),callback:function ($$v) {_vm.$set(item, "canUseSalaryFraction", $$v)},expression:"item.canUseSalaryFraction"}})]}},{key:"item.importSalaryClaimsOnly",fn:function({ item }){return [_c('v-checkbox',{attrs:{"color":"primary","label":_vm.$t(
              'generalSettingsStrings.tableHeaders.importSalaryClaimsOnly'
            ),"disabled":!item.integrationConfigurationId},model:{value:(item.importSalaryClaimsOnly),callback:function ($$v) {_vm.$set(item, "importSalaryClaimsOnly", $$v)},expression:"item.importSalaryClaimsOnly"}})]}},{key:"item.immutableSalaryClaimsCollection",fn:function({ item }){return [_c('v-checkbox',{attrs:{"color":"primary","label":_vm.$t(
              'generalSettingsStrings.labels.immutableSalaryClaimsCollection'
            ),"disabled":!item.integrationConfigurationId},model:{value:(item.immutableSalaryClaimsCollection),callback:function ($$v) {_vm.$set(item, "immutableSalaryClaimsCollection", $$v)},expression:"item.immutableSalaryClaimsCollection"}})]}},{key:"item.editableSalaryClaimPayingOrgs",fn:function({ item }){return [_c('v-checkbox',{attrs:{"color":"primary","label":_vm.$t(
              'generalSettingsStrings.tableHeaders.editableSalaryClaimPayingOrgs'
            ),"disabled":!item.integrationConfigurationId},model:{value:(item.editableSalaryClaimPayingOrgs),callback:function ($$v) {_vm.$set(item, "editableSalaryClaimPayingOrgs", $$v)},expression:"item.editableSalaryClaimPayingOrgs"}})]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"accent-inverted-button",attrs:{"disabled":_vm.disableCriticalSettings,"rounded":"","small":""},on:{"click":function($event){return _vm.removeIntegration(item)}}},[_vm._v(_vm._s(_vm.$t("commonStrings.remove")))])]}}],null,false,2588852959)}),_c('v-col',{attrs:{"cols":"12"}},[(_vm.notUsedIntegrationConfigurations.length)?_c('v-btn',{staticClass:"primary-inverted-button",attrs:{"rounded":""},on:{"click":_vm.addIntegration}},[_c('v-icon',{attrs:{"tag":"span","left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("generalSettingsStrings.addIntegration"))+" ")],1):_c('em',[_vm._v(_vm._s(_vm.$t("generalSettingsStrings.allIntegrationsUsed")))])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }