import { render, staticRenderFns } from "./DietStayRow.vue?vue&type=template&id=09af01ea&scoped=true"
import script from "./DietStayRow.vue?vue&type=script&lang=js"
export * from "./DietStayRow.vue?vue&type=script&lang=js"
import style0 from "./DietStayRow.vue?vue&type=style&index=0&id=09af01ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09af01ea",
  null
  
)

export default component.exports